// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-blog-template-js": () => import("./../../src/components/blogTemplate.js" /* webpackChunkName: "component---src-components-blog-template-js" */),
  "component---src-pages-alacarte-js": () => import("./../../src/pages/alacarte.js" /* webpackChunkName: "component---src-pages-alacarte-js" */),
  "component---src-pages-home-js": () => import("./../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

